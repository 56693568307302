import Cookies from "js-cookie";
import md5 from "md5";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { PERMISSION } from "../redux/action/type";

const PrivateRoute = ({ item }) => {
	const auth = Cookies.get(md5("bridgestone||token"));
	const { login } = useSelector((state) => state.authReducer);
	if (Object.keys(login).length != 0 && !PERMISSION[login.role].includes(item.permission)) {
		return <Navigate to="/dashboard" />;
	}
	return auth ? <Outlet /> : <Navigate to="/dashboard" />;
};

export default PrivateRoute;
