import React from "react";
import { ThreeDots } from 'react-loader-spinner'

export default function Loader() {
    return (
        <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="rgb(0 ,76 ,132)"
            ariaLabel="three-dots-loading"
            wrapperStyle={{ position: "fixed", top: "45vh", left: "48vw" }}
            wrapperClassName=""
            visible={true}
        />
    );
}