import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// React Toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./common/Loader";
import RoutesData from "./routes";

function App() {
  const [loader, setLoader] = useState(false);
  const userLoading = useSelector((state) => state.userReducer.loading);
  const authLoading = useSelector((state) => state.authReducer.loading);
  const reworkLoading = useSelector((state) => state.reworkReducer.loading);
  const dispatch = useDispatch();
  useEffect(() => {

    if (userLoading == true || authLoading == true || reworkLoading == true) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [dispatch]);
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover={false}
      />
      {loader ? <Loader /> : <RoutesData />}
    </>
  );
}

export default App;
