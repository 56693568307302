import { ADD_REWORK, ADD_REWORK_FAILURE, ADD_REWORK_SUCCESS, GET_DASHBOARD_SUCCESS, GET_REWORK, GET_REWORK_FAILURE, GET_REWORK_SUCCESS, UPDATE_REWORK, UPDATE_REWORK_FAILURE, UPDATE_REWORK_SUCCESS } from "../action/type";

const initialState = {
    rework: [],
    dashboard: {},
    pagination: { pagesCount: 0, dataCount: 0 },
    loading: false,
};
const reworkReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REWORK:
            return { ...state, lastAction: action.type, loading: true };
        case GET_REWORK_SUCCESS:
            return { ...state, lastAction: action.type, loading: false, rework: action.payload.data, pagination: { pagesCount: action.payload.pagination.pagesCount, dataCount: action.payload.pagination.dataCount } };
        case GET_DASHBOARD_SUCCESS:
            return { ...state, lastAction: action.type, loading: false, dashboard: action.payload };
        case GET_REWORK_FAILURE:
            return { ...state, lastAction: action.type, loading: false };
        case ADD_REWORK:
            return { ...state, lastAction: action.type, loading: true };
        case ADD_REWORK_SUCCESS:
            return { ...state, lastAction: action.type, loading: false, rework: action.payload.data, pagination: action.payload.pagination };
        case ADD_REWORK_FAILURE:
            return { ...state, lastAction: action.type, loading: false };
        case UPDATE_REWORK:
            return { ...state, lastAction: action.type, loading: true };
        case UPDATE_REWORK_SUCCESS:
            return { ...state, lastAction: action.type, loading: false, };
        case UPDATE_REWORK_FAILURE:
            return { ...state, lastAction: action.type, loading: false };


        default:
            return state;
    }
};

export default reworkReducer;
