import { combineReducers } from "redux";
import reworkReducer from './reworkReducer'
import authReducer from "./authReducer";
import userReducer from "./userReducer";

export default combineReducers({
  authReducer,
  reworkReducer,
  userReducer
});
