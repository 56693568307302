import { lazy } from "react";


// login module routes
const NewRework = lazy(() => import("../pages/Rework/NewRework"));
const SubmitRework = lazy(() => import("../pages/Rework/SubmitRework"));
const Login = lazy(() => import("../forms/Login"));
const ForgotPassword = lazy(() => import("../forms/Forgot"));

// dashbord
const Dashboard = lazy(() => import("../pages/Dashbord/Dashboard"));
const TwoCRework = lazy(() => import("../pages/TwoCRework"))
const UserManagement = lazy(() => import("../pages/UserManagement"))
const Reports = lazy(() => import("../pages/Reports"))

const configureRoute = [
  {
    path: "/",
    element: <Login />,
    private: false,
  },
  {
    path: "/login",
    exact: true,
    element: <Login />,
    private: false,
  },
  {
    path: "/auth/forgot-password",
    exact: true,
    element: <ForgotPassword />,
    private: false,
  },
  {
    path: "/dashboard",
    exact: true,
    permission: "DASHBOARD",
    element: <Dashboard />,
    private: true,
  },
  {
    path: "/rework",
    exact: true,
    permission: "NEW_TYRE_REWORK",
    element: <NewRework />,
    private: true,
  },
  {
    path: "/submission/",
    exact: true,
    permission: "SUBMIT_TYRE_REWORK",
    element: <SubmitRework />,
    private: true,
  },
  {
    path: "/2crework",
    exact: true,
    permission: "2C_REWORK",
    element: <TwoCRework />,
    private: true,
  },
  {
    path: "/user",
    exact: true,
    permission: "USER_MANAGEMENT",
    element: <UserManagement />,
    private: true,
  },
  {
    path: "/reports",
    exact: true,
    permission: "REPORTS",
    element: <Reports />,
    private: true,
  }
];

export default configureRoute;
