import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import configureRoute from "./routes";

// loader
import Loader from "../common/Loader";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

const RoutesData = () => {
	return (
		<>
			<Suspense fallback={<Loader />}>
				<Routes>
					{configureRoute.map((item, key) =>
						item.private ? (
							<Route key={key} exact element={<PrivateRoute item={item} />}>
								<Route
									key={key}
									exact={item.exact}
									path={item.path}
									name={item.name}
									element={item.element}
								/>
							</Route>
						) : (
							<Route key={key} exact element={<PublicRoute item={item} />}>
								<Route
									key={key}
									exact={item.exact}
									path={item.path}
									name={item.name}
									element={item.element}
								/>
							</Route>
						)
					)}
				</Routes>
			</Suspense>
		</>
	);
};

export default RoutesData;
