import Cookies from "js-cookie";
import md5 from "md5";

// TOKEN
export const BASE_TOKEN = Cookies.get(md5("bridgestone||token"));

// Login
export const LOADER_START = "LOADER_START";
export const LOADER_END = "LOADER_END";

// Login
export const PERMISSION = {
  MANAGER: [
    "DASHBOARD",
    "NEW_TYRE_REWORK",
    "SUBMIT_TYRE_REWORK",
    "2C_REWORK",
    "REPORTS",
    "USER_MANAGEMENT",
  ],
  OPERATOR: ["DASHBOARD", "NEW_TYRE_REWORK", "SUBMIT_TYRE_REWORK"],
  SUPERVISOR: ["DASHBOARD", "NEW_TYRE_REWORK", "SUBMIT_TYRE_REWORK", "REPORTS"],
  "SUPER-ADMIN": [
    "USER_MANAGEMENT",
    "DASHBOARD",
    "NEW_TYRE_REWORK",
    "SUBMIT_TYRE_REWORK",
    "2C_REWORK",
    "REPORTS",
  ],
};

// Login
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// GET_REWORK
export const GET_REWORK = "GET_REWORK";
export const GET_REWORK_SUCCESS = "GET_REWORK_SUCCESS";
export const GET_REWORK_FAILURE = "GET_REWORK_FAILURE";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";

// ADD_REWORK
export const ADD_REWORK = "ADD_REWORK";
export const ADD_REWORK_SUCCESS = "ADD_REWORK_SUCCESS";
export const ADD_REWORK_FAILURE = "ADD_REWORK_FAILURE";

// UPDATE_REWORK
export const UPDATE_REWORK = "UPDATE_REWORK";
export const UPDATE_REWORK_SUCCESS = "UPDATE_REWORK_SUCCESS";
export const UPDATE_REWORK_FAILURE = "UPDATE_REWORK_FAILURE";

// REMOVE_REWORK
export const REMOVE_REWORK = "REMOVE_REWORK";
export const REMOVE_REWORK_SUCCESS = "REMOVE_REWORK_SUCCESS";
export const REMOVE_REWORK_FAILURE = "REMOVE_REWORK_FAILURE";

// ADD_USER
export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";

// GET USER
export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

// UPDATE USER
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

// DELETE USER
export const REMOVE_USER = "REMOVE_USER";
export const REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS";
export const REMOVE_USER_FAILURE = "REMOVE_USER_FAILURE";
