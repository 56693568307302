import { LOGIN, LOGIN_FAILURE, LOGIN_SUCCESS } from "../action/type";

const initialState = {
  login: {},
  loading: false,
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state, lastAction: action.type, loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state, lastAction: action.type, login: action.payload,
        loading: false,
      };
    case LOGIN_FAILURE:
      return {
        ...state, lastAction: action.type, loading: false,
      };
    default:
      return state;
  }
};

export default authReducer;
