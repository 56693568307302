import { ADD_USER, ADD_USER_FAILURE, ADD_USER_SUCCESS, GET_USER, GET_USER_FAILURE, GET_USER_SUCCESS, REMOVE_USER, REMOVE_USER_FAILURE, REMOVE_USER_SUCCESS, UPDATE_USER, UPDATE_USER_FAILURE, UPDATE_USER_SUCCESS } from "../action/type";

const initialState = {
    user: [],
    pagination: { pagesCount: 0, dataCount: 0 },
    lastAction: null,
    loading: false,
};
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER:
            return { ...state, lastAction: action.type, loading: true };
        case GET_USER_SUCCESS:
            return { ...state, lastAction: action.type, loading: false, user: action.payload.data, pagination: { pagesCount: action.payload.pagination.pagesCount, dataCount: action.payload.pagination.dataCount } };
        case GET_USER_FAILURE:
            return { ...state, lastAction: action.type, loading: false };
        case ADD_USER:
            return { ...state, lastAction: action.type, loading: true };
        case ADD_USER_SUCCESS:
            return { ...state, lastAction: action.type, loading: false };
        case ADD_USER_FAILURE:
            return { ...state, lastAction: action.type, loading: false };
        case UPDATE_USER:
            return { ...state, lastAction: action.type, loading: true };
        case UPDATE_USER_SUCCESS:
            return { ...state, lastAction: action.type, loading: false, };
        case UPDATE_USER_FAILURE:
            return { ...state, lastAction: action.type, loading: false };
        case REMOVE_USER:
            return { ...state, lastAction: action.type, loading: true };
        case REMOVE_USER_SUCCESS:
            return { ...state, lastAction: action.type, user: state.user.filter(item => item._id !== action.payload), loading: false, };
        case REMOVE_USER_FAILURE:
            return { ...state, lastAction: action.type, loading: false };

        default:
            return state;
    }
};

export default userReducer;
