import Cookies from "js-cookie";
import md5 from "md5";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PublicRoute = ({ permission }) => {
	const auth = Cookies.get(md5("bridgestone||token"));
	return auth ? <Navigate to="/dashboard" /> : <Outlet />;
};

export default PublicRoute;
